<template>
  <div class="w-full flex items-center justify-center bg-pure-white">
    <div class="max-w-370">
      <p class="text-style-4 mb-32">
        {{ $t('modal.authentication.reset_password_set_password.description_text') }}
      </p>
      <AuthForm
          :is-reset-password-set-password-type="true"
          :submit-button-label="$t(`modal.authentication.reset_password_set_password.button_text`)"
      />
    </div>
  </div>
</template>


<script>
import AuthForm from '@/components/auth/AuthForm';


export default {
  components: {
    AuthForm,
  },
  created() {
    this.$router.replace({
      params: {
        locale: this.$route.query.lang || 'de',
      },
      query: this.$route.query,
    });
  },
};
</script>


<style lang="scss" scoped>

</style>
